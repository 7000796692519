@import url('https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap');

* {
  box-sizing: border-box;
}

.MainContent {
  overflow: scroll;
}

.department-tile {
  background-size: cover;
  background-position: center;
  display: block;
  position: relative;
  transition: 0.2s;
}
.App-logo {
  height: 50px;
  pointer-events: none;
}

.App-container {
  background-color: #fff;
  font-size: calc(10px + 2vmin);
  color: #000;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.App-container-backdrop {
  text-align: center;
  background-image: url('./assets/images/webbg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 1 1 0px;
}

.App-container-backdrop-home {
  text-align: center;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
    url('./assets/images/bg_sll.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 1 1 0px;
}

.App-container-backdrop-apply {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3)),
    url('./assets/images/bg_sll.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-container-backdrop-black {
  text-align: center;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex: 1 1 0px;
}

.App-link {
  color: #61dafb;
}

a.social-link:hover {
  color: firebrick !important;
  transition: 0.2s;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.vjs-loading-spinner {
  display: none !important;
}
